import React, { useEffect} from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ProjectsContainer = styled.div`
  width: 100%;
`;

const ProjectFilters = styled.div`
  width: 100%;
  margin: var(--spacing-m) auto;
  
  ul {
    list-style: none inside none;
    display: flex;
    margin: 0 auto;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 90%;
        flex-wrap: wrap;
        margin-top: var(--spacing-xl);
    }
    
    li {
        font-size: 1rem;
        font-family: var(--font-small);
        color: #202020;
        padding: var(--spacing-s) var(--spacing-m);
        transition: background .4s;
        border-radius: 4px;
        
        &:hover, &.active {
            background: var(--primary-color);
            color: #FFF;
        }
    }
  }
`;

const ProjectItemsContainer = styled.div`
  width: 100%;
  padding: var(--spacing-xxl);
  background: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: var(--spacing-m);  
    flex-direction: column;
  }
`;

const ProjectItem = styled.div`

  flex-basis: 25%;
  margin: 0;
  padding: 0;
  width: 100%;
  opacity: 1;
  transition: opacity .4s, display .2s;
  
  &.hide {
    opacity: 0;
  } 
  
  &.remove {
    display: none;
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-basis: 100%; 
  }
  
  .effect-oscar {
    position: relative;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 400px;
    max-height: 400px;
    width: 48%;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(45deg, #22682a 0%,#9b4a1b 40%,#3a342a 100%);
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 98%;
    }
    
    figcaption::before {
        position: absolute;
        top: 30px;
        right: 30px;
        bottom: 30px;
        left: 30px;
        border: 1px solid #fff;
        content: '';
        opacity: 0;
        transition: opacity 0.35s, transform 0.35s;
        transform: scale(0);
    }
    
    &:hover {
    
        img {
            opacity: 0.4;
        }
        figcaption::before, p {
            opacity: 1;
            transform: scale(1);
        }
        
        h2 {
            opacity: 1;
            transform: scale(1);
            transform: translate3d(0,0,0);
        }
        
        figcaption {
            background-color: rgba(58,52,42,0.4);
        }
    }
    figcaption {
        padding: 3em;
        transition: background-color .35s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }
    img {
        opacity: .9;
        transition: opacity 0.35s;
        position: relative;
        display: block;
        min-height: 100%;
        max-width: 100%;
    }
    h2 {
        font-family: var(--font-large);
        color: #FFF;
        margin: 20% 0 10px 0;
        opacity: 0;
        transform: scale(0);
        transition: transform .35s, opacity .35s;
        transform: translate3d(0, 100%, 0);
    }
    p {
        opacity: 0;
        font-family: var(--font-small);
        color: #FFF;
        transition: opacity .35s, transform .35s;
        transform: scale(0);
    }
  }
`;


export default function Projects({ data }) {
  const { projects, projectCategories } = data;
  const allProjects = projects.nodes;
  const allCategories = projectCategories.nodes;
  let preSelectCategory;
  let category;
  if(typeof window !== `undefined`) {
      preSelectCategory = location.hash.split('=');
      [, category] = preSelectCategory;
  }

  const showAll = () => {
      const projectsToShow = document.querySelectorAll('div[data-type]');
      const projectCategories = document.querySelectorAll('li[data-category]');
      Array.from(projectsToShow).forEach(projectToShow => {
          projectToShow.classList.remove('remove');
          projectToShow.classList.remove('hide');
      });
      Array.from(projectCategories).forEach(projectCategory => {
          if (projectCategory.dataset.category === '0') {
              projectCategory.classList.add('active');
           } else {
              projectCategory.classList.remove('active');
          }
      });
  }

  const onlyShow = (categoryToShowId) => {
      const allOtherProjects = document.querySelectorAll(`div[data-type]:not([data-type="${categoryToShowId}"])`);
      const projectsToShow = document.querySelectorAll(`div[data-type="${categoryToShowId}"]`);
      const projectCategories = document.querySelectorAll('li[data-category]');

      Array.from(projectCategories).forEach(projectCategory => {
          if (projectCategory.dataset.category === categoryToShowId) {
              projectCategory.classList.add('active');
          } else {
              projectCategory.classList.remove('active');
          }
      });

      Array.from(allOtherProjects).forEach(projectToHide => {
          projectToHide.classList.remove('show');
          setTimeout(() => {
              projectToHide.classList.add('remove');
          }, 200)
      });
      Array.from(projectsToShow).forEach(projectToShow => {
          projectToShow.classList.remove('remove');
          projectToShow.classList.remove('hide');
      });
  }

  const goToDetails = (slug) => {
      if(typeof window !== `undefined` && typeof document !== 'undefined') {
          document.location.href = `/project/${slug}`;
      }
  };

  useEffect(() => {
      if (category) {
          const showProjectsFromCategory = decodeURI(category);
          const projectCategoryToPreselect = document.querySelector(`li[data-title="${showProjectsFromCategory}"]`);
          onlyShow(projectCategoryToPreselect.dataset.category);
      }
  }, [])

  return (
    <>
      <SEO
        title={'Projecten van Chrisbakkerdak'}
        description={'De projecten kunt u als referentie of inspiratie gebruiken voor uw eigen project of klus. Neem vrijblijvend contact op voor een offerte of vragen.'}
      />
      <ProjectsContainer>
        <ProjectFilters>
          <ul>
              <li data-category="0" className={'active'} onClick={showAll}>Alle projecten</li>
              {allCategories.map(projectCategory => {
                 return (
                     <li data-title={projectCategory.title} data-category={projectCategory.id} key={projectCategory.id} onClick={() => { onlyShow(projectCategory.id)}} role={'button'}>{projectCategory.title}</li>
                 )
              })}
          </ul>
        </ProjectFilters>
        <ProjectItemsContainer>
          {allProjects.map((project) => (
            <ProjectItem key={project.id} data-type={project.category.id} onClick={() => { goToDetails(project.slug.current) }}>
                <figure className={'effect-oscar'}>
                    <Img fluid={project.images[0].asset.fluid} alt={project.title} />
                    <figcaption>
                        <h2>{project.title}</h2>
                        <p>{project.description}</p>
                    </figcaption>
                </figure>
            </ProjectItem>
          ))}
        </ProjectItemsContainer>
      </ProjectsContainer>
    </>
  );
}

export const query = graphql`
  query allProjectOverview {
    projects: allSanityProjects(filter: {isVisible: {eq: true}}) {
      nodes {
        images {
          asset {
            fluid(maxWidth: 600, maxHeight: 600) {
                ...GatsbySanityImageFluid
            }
          }
        }
        category {
          id
        }
        slug {
            current
        }
        description
        id
        title
      }
    }
    projectCategories: allSanityCategory(sort: {fields: order, order: ASC}) {
        nodes {
          title
          id
        }
      }
  }
`;
